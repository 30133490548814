import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { estoqueStore } from '@/store';
import _ from 'lodash';
let EstoqueList = class EstoqueList extends Vue {
    constructor() {
        super(...arguments);
        this.headers = [
            {
                text: 'Livro',
                sortable: true,
                value: 'nome',
                align: 'left',
            },
            {
                text: 'Saldo em estoque',
                sortable: true,
                value: 'saldo',
                align: 'left',
            },
            {
                text: 'Última movimentação',
                sortable: true,
                value: 'updated_at',
                align: 'left',
            },
            {
                text: 'Ações',
                value: 'actions',
            },
        ];
        this.somenteObtidos = false;
        this.estoque = [];
        this.search = '';
        this.loading = false;
    }
    get filteredEstoque() {
        return _.filter(this.estoque, (element) => {
            if (this.somenteObtidos) {
                return element.saldo > 0;
            }
            else {
                return true;
            }
        });
    }
    get saldoTotalLivrosAluno() {
        let saldo = 0;
        _.forEach(this.estoque, (livro) => {
            if (livro.saldo > 0) {
                if (!livro.nome.match(/GUIDE.*/)) {
                    saldo += livro.saldo;
                }
            }
        });
        return saldo;
    }
    get saldoTotalGuide() {
        let saldo = 0;
        _.forEach(this.estoque, (livro) => {
            if (livro.saldo > 0) {
                if (livro.nome.match(/GUIDE.*/)) {
                    saldo += livro.saldo;
                }
            }
        });
        return saldo;
    }
    async mounted() {
        this.loading = true;
        this.estoque = await estoqueStore.getEstoque();
        this.loading = false;
    }
    routeCreate() {
        // @ts-ignore
        this.$router.push({ name: 'main-estoque-create' });
    }
    routeEdit(livro_id) {
        // @ts-ignore
        this.$router.push({ name: 'main-estoque-edit', params: { livro_id } });
    }
};
EstoqueList = __decorate([
    Component
], EstoqueList);
export default EstoqueList;
