var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"ma-1 pa-3 rounded-xl mb-3"},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',{staticClass:"headline primary--text"},[_vm._v("Estoque")])]),_c('v-card-text',[[(_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"card-avatar, article"}}):_vm._e(),_c('v-row',[_c('v-col',[_c('v-checkbox',{attrs:{"label":"Somente livros com saldo em estoque"},model:{value:(_vm.somenteObtidos),callback:function ($$v) {_vm.somenteObtidos=$$v},expression:"somenteObtidos"}})],1)],1),_c('v-row',{staticClass:"mt-1"},[_c('v-col',{attrs:{"cols":"auto"}},[_c('b',[_vm._v("Total de Livros de Aluno: "+_vm._s(_vm.saldoTotalLivrosAluno))])]),_c('v-col',{attrs:{"cols":"auto"}},[_c('b',[_vm._v("Total de Livros Guide: "+_vm._s(_vm.saldoTotalGuide))])])],1),_c('v-text-field',{staticClass:"ma-1",attrs:{"clearable":"","flat":"","hide-details":"","prepend-inner-icon":"mdi-magnify","label":"Buscar"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{attrs:{"footer-props":{ itemsPerPageOptions: [-1] },"headers":_vm.headers,"items":_vm.filteredEstoque,"loading":_vm.loading,"search":_vm.search,"fixed-header":"","height":"80vh","hide-default-footer":"","loading-text":"Carregando...","multi-sort":"","dense":""},scopedSlots:_vm._u([{key:"item.nome",fn:function(ref){
var item = ref.item;
return [(item)?_c('span',[_vm._v(" "+_vm._s(item.nome)+" "),(item.edicao)?_c('span',[_vm._v("- "+_vm._s(item.edicao))]):_vm._e()]):_vm._e()]}},{key:"item.saldo",fn:function(ref){
var value = ref.value;
return [(value)?_c('span',[_vm._v(" "+_vm._s(value)+" ")]):_c('span',[_vm._v(" 0 ")])]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.formatDateTime(item.updated_at))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.routeEdit(item.livro_id)}}},[_vm._v("mdi-eye")])]}},{key:"footer",fn:function(ref){
var headers = ref.headers;
return undefined}}])})]],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }